/* eslint-disable camelcase */
import axios, { AxiosError, AxiosRequestConfig, Method } from "axios";
import http from "http";
import https from "https";
import getConfig from "next/config";
import mockArticle from "mocks/MockArticle.json";
import mockCarmen from "mocks/carmen.json";
import mockLocations from "mocks/mockLocations.json";
import mockMayaResponse from "mocks/mockMayaResponse.json";
import mockMayaCensored from "mocks/mockMayaCensored.json";
import {
    JobSearchRequest, JobSearchResults, UserLocation
} from "@themuse/design-system/lib/main";

import { isBrowser } from "utils/browser";
import { getFetchError } from "utils/errors";
import { IFeaturedCompanies } from "interfaces/featuredCompanies";
import { IArticleTile } from "Homepage/RecentArticles";
import { get, MetadataObj } from "./objects";
import { formatLog } from "./logging";
import { jobSearchQueryMaxlength } from "./constants";
import { Dispatch, SetStateAction } from "react";

export interface CompanyValue {
    object: "company_name_suggestion",
    // eslint-disable-next-line camelcase
    short_name: string
}

export interface CompanySuggestion {
    object: "suggestion",
    type: "company_name",
    text: string
    value: CompanyValue,
    image: string
}

export interface CompanySuggestorResults {
    object: "suggestions",
    data: CompanySuggestion[]
}

interface FilterArgs {
    [key: string]: string | number | string[];
}

export interface ApiArticleBreadcrumbLink {
    title: string;
    uri: string;
}

export interface ApiArticleBodyContent {
    html: string;
    type: string;
}

export interface ApiArticleCompanyReference {
    id: number;
    legacyId: string;
    primaryTopic: string;
    slug: string;
    status: string;
    tags: string;
    title: string;
    uri: string;
    url: string;
}

export interface ApiArticleCompanySponsor {
    id: number;
    description: string;
    legacyId: string;
    logoImage: string;
    primaryImage: string;
    secondaryImage: string;
    shortName: string;
    slug: string;
    status: string;
    tertiaryImage: string;
    title: string;
}

export interface ApiArticleCompanySponsorJob {
    id: number;
    name: string;
    short_name: string;
    level_displays: string[];
    location_values: string[];
    company_logo_image: string;
    company_name: string;
    company_short_name: string;
    company_id: number;
    apply_link: string;
    external_apply_link: string;
    model_type: string;
    relevance_score: number;
}

export interface ApiArticleImageAsset {
    altText: string;
    caption: string | null;
    credit: string | null;
    creditLink: string | null;
    height: number;
    url: string;
    width: number;
}

export interface ApiArticleCmsEntity {
    id: number;
    legacyId : string| null;
    primaryTopic: string | null;
    slug: string | null;
    status: string | null;
    tags: any;
    title: string | null;
    uri: string | null;
    url: string | null;
}

export interface ApiArticleSeo {
    MetaTitleContainer: string;
    MetaTagContainer: string;
    MetaLinkContainer: string;
    MetaScriptContainer: string;
    MetaJsonLdContainer: string;
}

export interface ApiArticleWriter {
    id: number;
    dateUpdated: string;
    email: string;
    enabled: boolean;
    isCoach: boolean;
    legacyId: string | null;
    linkedinProfile: string | null;
    longBiography: string | null;
    slug: string
    twitterHandle: string | null;
    uri: string;
    website: string | null;
    writerName: string;
    writerTitle: string | null;
    biography: string | null;
    headshot: ApiArticleImageAsset[];
}

export interface ApiArticleMeta {
    status: string;
    section: string;
    type: string;
    api: {
        version: string;
    },
    lastUpdated: string;
    location?: string;
    statusCode?: number;
}

export interface ApiCareerType {
    title: string,
    slug: string,
    id: number
}

// ApiArticle reflects the data type that is returned from the API,
// which is distinct from the data we actually pass to the frontend.
export interface ApiArticle {
    additionalTopics: ApiArticleCmsEntity[];
    articleBodyContent: ApiArticleBodyContent[];
    articleContentType: string;
    availableForSyndication: boolean;
    breadcrumbLinks: ApiArticleBreadcrumbLink[];
    careerTypes: ApiCareerType[];
    companiesReferenced: ApiArticleCompanyReference[];
    sponsoredJobs: ApiArticleCompanySponsorJob[];
    companySponsor: ApiArticleCompanySponsor;
    companySponsorExpiration: string | null;
    contentEditor: any; // Unknown format
    dateCreated: string;
    dateUpdated: string;
    enabled: boolean;
    excerpt: string | null;
    id: number;
    includeInRecents: boolean;
    jobLevel: any[]; // Unknown format, possibly unused
    jwPlayerVideo: string;
    leadMediaCaption: string | null;
    leadMediaPhoto: ApiArticleImageAsset | ApiArticleImageAsset[];
    leadMediaVideo: any; // Unknown format, possibly unused
    legacyId: string | null;
    legacyTags: ApiArticleCmsEntity[];
    location: any[]; // Unknown format, possibly unused
    postDate: string;
    primaryTopic: ApiArticleCmsEntity;
    product: ApiArticleCmsEntity;
    seo: ApiArticleSeo;
    slug: string;
    sponsorCredit: string | null;
    sponsorDescription: string | null;
    sponsorLink: string | null;
    tags: ApiArticleCmsEntity[];
    templateName?: string;
    title: string;
    uri: string;
    wasMigrated: boolean;
    writerAltText: string | null;
    writer: ApiArticleWriter;
    meta: ApiArticleMeta;
    listBuilder?: ApiArticleBodyContent[];
}

export interface NewsletterSubmissionValues {
    email: string
}

// Max length for job-search suggestion api input
export const SUGGESTION_INPUT_MAX_LENGTH = 70;

/* eslint-disable-next-line max-len */
export const fetchJobRecommendations = async (axiosOptions?: AxiosRequestConfig) => {
    const { publicRuntimeConfig: { apiRequestTimeoutMs, apiBase } } = getConfig();
    const response = await axios.get(`${apiBase}/recommendations`, { ...axiosOptions, ...{ timeout: apiRequestTimeoutMs } });
    return (response as any).data;
};

/**
 * Request the user on the back end.
 */
export const fetchUser = async (axiosOptions?: AxiosRequestConfig) => {
    const { publicRuntimeConfig: { apiRequestTimeoutMs, apiBase } } = getConfig();
    const config: AxiosRequestConfig = {
        ...{
            timeout: apiRequestTimeoutMs,
            headers: {
                "Content-Type": "application/json",
            }
        },
        ...axiosOptions
    };
    const path = `${apiBase}/user`;
    try {
        const response = await axios(path, config);
        return response.data;
    } catch (error) {
        const msg = getFetchError("User", "Back-End", path);
        console.error(msg, JSON.stringify(formatLog({ error })));
        return null;
    }
};

/**
 * Request user from the museweb front end.
 */
export const fetchApiUser = async (oskarId = "", sessionId = "", axiosOptions?: AxiosRequestConfig) => {
    const {
        publicRuntimeConfig: {
            apiRequestTimeoutMs, museApiHost, museHost
        }
    } = getConfig();
    const config: AxiosRequestConfig = {
        ...{
            timeout: apiRequestTimeoutMs,
            headers: {
                "Content-Type": "application/json",
                cookie: `oskar_id=${oskarId}; session_id=${sessionId}`,
                referer: museHost
            }
        },
        ...axiosOptions
    };
    const path = `${museApiHost}/api/user`;
    try {
        const response = await axios(path, config);
        return response;
    } catch (error) {
        const msg = getFetchError("User", "Back-End", path);
        console.error(msg, JSON.stringify(formatLog({ error })));
        return null;
    }
};

/**
 * Request locations from the backend API.
 */
export const fetchLocationsPrivate = async (userInput: string) => {
    const location = userInput?.substring(0, 70);
    const {
        serverRuntimeConfig: { jobsApiHost, mockApiResponses },
    } = getConfig();
    const config = {
        params: {
            type: "location",
            query: location ?? ""
        },
        timeout: 30_000,
    };
    const path = `${jobsApiHost}/api/v2/suggestions`;
    try {
        const response = mockApiResponses
            ? { data: mockLocations }
            : await axios.get(path, config);
        return response.data;
    } catch (error) {
        const msg = getFetchError("Location", "Back-End", path);
        console.error(msg, JSON.stringify(formatLog({ error })));
        throw new Error(msg);
    }
};

/**
 * Request location suggestions.
 */
export const fetchLocations = async (inputValue: string) => {
    if (!inputValue) {
        return null;
    }
    const { publicRuntimeConfig: { apiBase } } = getConfig();
    const config = {
        url: `${apiBase}/locations?query=${inputValue}`,
        method: ("GET" as Method),
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json"
        },
        timeout: 30_000,
    };

    try {
        const response = await axios(config);
        return response.data;
    } catch (error) {
        const msg = getFetchError("Location Suggestions", "Back-End", config.url);
        console.error(msg, JSON.stringify(formatLog({ error })));
        throw new Error(msg);
    }
};

/**
 * newsletter optin from the public API.
 */
export const newsletterSubscribeRequest = async (values: NewsletterSubmissionValues, axiosOptions?: AxiosRequestConfig) => {
    const { publicRuntimeConfig: { apiRequestTimeoutMs, appName, apiBase } } = getConfig();

    const config: AxiosRequestConfig = {
        ...{
            timeout: apiRequestTimeoutMs,
            headers: {
                "Content-Type": "application/json",
            }
        },
        ...axiosOptions
    };
    const path = `${apiBase}/newsletter/subscribe`;

    try {
        const response = await axios.post(path, values, config);
        if (response.status === 200 || response.status === 201) {
            return true;
        }
        throw new Error(`${appName}: Sendgrid newsletter subscription was unsuccessful. Status: ${response.status}`);
    } catch (error) {
        console.error(JSON.stringify(formatLog({ error })));
        return false;
    }
};

/**
 * newsletter opt in from the service API.
 */
export const newsletterApiSubscribeRequest = async (email: string, axiosOptions?: AxiosRequestConfig) => {
    const {
        publicRuntimeConfig: {
            apiRequestTimeoutMs, museApiHost, appName, museHost
        },
        serverRuntimeConfig: { emailAlertToken }
    } = getConfig();

    const emailSubscription = {
        email,
        page_section: "advice"
    };

    const config = {
        ...{
            timeout: apiRequestTimeoutMs,
            headers: {
                Authorization: `Shared-Token token=${emailAlertToken}`,
                Origin: museHost,
            }
        },
        ...axiosOptions
    };

    const path = `${museApiHost}/api/newsletter/subscribe_with_token`;

    try {
        const response = await axios.post(path, emailSubscription, config);
        return response.data;
    } catch (error) {
        console.error(`${appName}: newsletter API request to ${path} failed.`, JSON.stringify(formatLog({ error })));
        return null;
    }
};

/**
 * @param  {string} companyName  - Company Name
 * @param  {number} page         - Page interval to retrieve
 * @param  {number} itemsPerPage - Items returned per page
 */
export const getJobsForCompany = async (companyName: string, page: number, itemsPerPage?: number) => {
    const { serverRuntimeConfig: { apiRequestTimeoutMs, jobsApiHost } } = getConfig();
    if (!companyName) {
        return { data: [] };
    }
    const company = encodeURIComponent(companyName);
    const config: AxiosRequestConfig = { timeout: apiRequestTimeoutMs };
    const apiPath = "/v1/jobs/search-sponsored-jobs-module";
    const query = `items_per_page=${itemsPerPage}&page=${page}&company=${company}`;
    const path = `${jobsApiHost}${apiPath}?${query}`;
    try {
        const response = await axios(path, config);
        return {
            data: get(response, "data.results")
        };
    } catch (error) {
        const msg = getFetchError("Company Job Count", "Back-End", path);
        console.error(msg, JSON.stringify(formatLog({ error })));
        return null;
    }
};

/**
 * @param  {FilterArgs} filterArgs - Args for filtering posts
 */
export const getOptimizedPosts = async (filterArgs: FilterArgs) => {
    const { serverRuntimeConfig: { articlesApiHost, mockApiResponses } } = getConfig();
    const { uri, token, preview } = filterArgs;
    const config: AxiosRequestConfig = { timeout: 3000 };
    const query = token && preview ? `?x-craft-live-preview=${preview}&token=${token}` : "";
    const path = `${articlesApiHost}/api/${uri}${query}`;

    if (mockApiResponses) {
        const response = mockArticle;
        return {
            data: get(response, "data.data"),
            meta: get(response, "data.metadata")
        };
    }
    let response: MetadataObj;
    try {
        response = await axios.get(path, config);
    } catch (error) {
        const msg = getFetchError("Post", "Back-End", path);
        console.error(msg, JSON.stringify(formatLog({ error })));
        console.error(msg, JSON.stringify(formatLog({ error })));
    }

    return {
        data: get(response, "data.data"),
        meta: get(response, "data.metadata")
    };
};

/**
 * Retrieves recent articles from the backend API.
 *
 * This function makes an asynchronous call to the backend API to fetch recent articles.
 * It constructs a list of optimized article tiles with specific properties like id, lead media photo, company sponsor, primary topic, title, uri, date created, date updated, and writer details.
 * If successful, it returns the list of optimized article tiles.
 * If an error occurs during the API call, it logs the error message and returns an empty array.
 *
 * @returns A promise that resolves to an array of optimized article tiles.
 */
export const getRecentArticles = async (): Promise<IArticleTile[]> => {
    const { serverRuntimeConfig: { museHost, articlesApiHost } } = getConfig();
    const config: AxiosRequestConfig = {
        timeout: 5000,
        headers: {
            "content-type": "application/json;charset=UTF-8"
        }
    };
    const path = `${articlesApiHost}/api/posts?type=articles,listicles&status=live&exclude=seo&page=1&limit=6`;

    try {
        const response = await axios.get(path, config);
        const optimizedPosts: IArticleTile[] = response?.data?.data?.map((article: any) => {
            const {
                id,
                leadMediaPhoto,
                primaryTopic,
                title,
                uri,
                dateCreated,
                dateUpdated,
            } = article;
            const sponsor = article.companySponsor.pop();
            const writer = article.writer.pop();
            return {
                id,
                leadMediaPhoto,
                primaryTopic,
                title,
                uri,
                dateCreated,
                dateUpdated,
                writer: {
                    uri: writer.uri,
                    writerName: writer.writerName
                },
                companySponsor: sponsor ? {
                    id: sponsor.id,
                    status: "live",
                    shortName: sponsor.shortName,
                    url: `${museHost}/${sponsor.url}`,
                    logoImage: sponsor.logoImage
                } : {}
            };
        });
        return optimizedPosts;
    } catch (error) {
        const msg = getFetchError("Post", "Back-End", path);
        console.error(msg, JSON.stringify(formatLog({ error })));
    }

    return [];
};

/**
 * Request company suggestions from the service API.
 */
export const fetchApiCompanySuggestions = async (query: string): Promise<CompanySuggestorResults> => {
    const { serverRuntimeConfig: { apiRequestTimeoutMs, jobsApiHost } } = getConfig();
    // if query exists we will pass it in the params.
    const queryObj = query ? { query } : {};
    const config: AxiosRequestConfig = { params: { type: "company_name", ...queryObj }, timeout: apiRequestTimeoutMs };
    const path = `${jobsApiHost}/api/v2/suggestions`;
    try {
        const response = await axios(path, config);
        return response.data;
    } catch (error) {
        const msg = getFetchError("Company Suggestions", "Back-End", path);
        console.error(msg, JSON.stringify(formatLog({ error })));
        return null;
    }
};

/**
 * Request company suggestions.
 */
export const fetchCompanySuggestions = async (inputValue: string, axiosOptions?: AxiosRequestConfig) => {
    const { publicRuntimeConfig: { apiRequestTimeoutMs, apiBase } } = getConfig();
    const path = `${apiBase}/company-suggestion/${inputValue}`;
    try {
        const response = await axios(path, { ...axiosOptions, ...{ timeout: apiRequestTimeoutMs } });
        return response.data;
    } catch (error) {
        const msg = getFetchError("Company Suggestions", "Front-End", path);
        console.error(msg, JSON.stringify(formatLog({ error })));
        return null;
    }
};

export const fetchHomepageCompanySuggestions = async (_: string, inputValue: string, axiosOptions?: AxiosRequestConfig) => {
    const { publicRuntimeConfig: { apiRequestTimeoutMs, apiBase } } = getConfig();
    const path = `${apiBase}/company-suggestion/${inputValue}`;
    try {
        const response = await axios(path, { ...axiosOptions, ...{ timeout: apiRequestTimeoutMs } });
        return response.data;
    } catch (error) {
        const msg = getFetchError("Company Suggestions", "Front-End", path);
        console.error(msg, JSON.stringify(formatLog({ error })));
        return null;
    }
};

/**
 * Get data from Carmen using requesters ip address by default
 * @param ip optional IP address to lookup
 */

export const fetchApiCarmenData = async (ip?: string, axiosOptions?: AxiosRequestConfig): Promise<UserLocation> => {
    const { publicRuntimeConfig: { apiRequestTimeoutMs, carmenApiHost } } = getConfig();
    const carmenPath = ip ? `/ip/${ip}` : "/me";
    const path = `${carmenApiHost}${carmenPath}`;

    // Local machines cannot access carmen. It must be a public IP. So we return a mock.
    if (ip === "172.20.0.1") {
        return mockCarmen;
    }

    try {
        const response = await axios(path, { ...axiosOptions, ...{ timeout: apiRequestTimeoutMs } });
        return response.data;
    } catch (error) {
        const msg = getFetchError("Carmen Location", "Front-End", path);
        console.error(msg, JSON.stringify(formatLog({ error })));
        return null;
    }
};

export interface MayaRequest {
    mayaApi: string;
    query: string,
}

export interface MayaResponse {
    links: MayaResponseLinks[]
    original_query: string;
    response_html: string;
}

export interface MayaResponseLinks {
    articleId: number;
    articleUri: string;
    mediaUr: string;
    title: string;
    url: string;
    writerName: string;
}

/**
 * Delays the execution of a callback function by a specified number of milliseconds.
 *
 * @param cb - A callback function that returns a Promise.
 * @param ms - The number of milliseconds to delay the execution.
 * @returns A Promise that resolves with the result of the callback function after the delay.
 */
export const delay = (cb: () => Promise<any> , ms: number) => {
    return new Promise(resolve => {
        setTimeout(() => resolve(cb()), ms);
    });
};

/**
 * Fetches a response from the ArticleRenderer's Maya API based on the provided query.
 *
 * * If the query is empty, returns null immediately.
 * * If the query is "mock", returns a mocked Maya response after a delay.
 * * If the query is "error", returns a mocked error response after a delay.
 * * For other queries, attempts to fetch a response from the Maya Backend API.
 * * If the fetch fails, logs the error and returns an error response.
 *
 * @param {MayaRequest} props - The request object containing the query.
 * @returns {Promise<Record<string, any> | null>} A promise that resolves to the API response or null.
 */
export const getMayaResponse = async (props: MayaRequest): Promise<Record<string, any> | null> => {
    const { query } = props;

    if (!query) {
        return delay(() => Promise.resolve(null), 0);
    };

    if (query === "mock") {
        return delay(() => Promise.resolve(mockMayaResponse), 3000);
    }

    if (query === "error") {
        return delay(() => Promise.resolve(mockMayaCensored), 3000);
    }

    const result = () => fetch(`/maya_api?prompt=${query}`)
        .then((response: any) => {
            if (!response.ok) {
                return response.text()
                    .then((text: string) => {
                        throw new Error(text);
                    });
            }
            return response.json();
        })
        .catch((error: Error) => {
            const msg = getFetchError("Maya", "Front-End", "/maya_api");
            console.error(msg, JSON.stringify(formatLog({ error })));
            return {
                original_query: query,
                response_type: "error",
                response_text: "I'm sorry, we're run into an error. Please try again later.",
            };
        });
        return delay(result, 0);
};

/**
 * Sends a POST request to the Maya backend server with the provided query string.
 *
 * @param query - The query string to be sent to the Maya server.
 * @returns A promise that resolves to the server's response data or an error.
 */
export const getMayaServerResponse = async (query: string): Promise<Error | void> => {
    const { serverRuntimeConfig: { mayaApi } } = getConfig();
    const mayaFetchConfig = {
        method: "POST",
        headers:{
            'Content-Type': 'application/x-www-form-urlencoded'
          },
        body: new URLSearchParams({
            'query': query
        })
    }
    const msg = getFetchError("Maya", "Back-End", mayaApi);
    try {
        const testResponse = await fetch(mayaApi, mayaFetchConfig);
        const data = await testResponse.json();
        return data;
    } catch (error) {
        throw new Error(msg, { cause: error });
    }
};

export const searchJobs = async (queryParams: JobSearchRequest, cursor: string = "") => {
    const { serverRuntimeConfig: { jobsApiHost } } = getConfig();
    const { publicRuntimeConfig: { apiBase, apiRequestTimeoutMs } } = getConfig();
    const config: AxiosRequestConfig = {
        timeout: apiRequestTimeoutMs,
        params: {
            start_after: cursor || null,
            ...queryParams,
            ...(queryParams.query && { query: queryParams.query.substring(0, jobSearchQueryMaxlength) })
        }
    };

    const path = isBrowser()
        ? `${apiBase}/job-search`
        : `${jobsApiHost}/api/v2/search/jobs`;

    const httpAgentConfig = {
        keepAlive: true,
        timeout: 5000,
        maxSockets: Infinity
    };

    const jobRendererAxiosClient = axios.create({
        timeout: 5000,
        httpAgent: new http.Agent(httpAgentConfig),
        httpsAgent: new https.Agent(httpAgentConfig),
        maxRedirects: 10,
        maxContentLength: 50 * 1000 * 1000
    });

    const result = await jobRendererAxiosClient.get(path, config)
        .catch((error: AxiosError) => {
            const msg = getFetchError("Company Job Count", "Back-End", path);
            console.error(msg, JSON.stringify(formatLog({ error })));
        })
        .then((res: any) => res?.data);
    return result as JobSearchResults;
};

export class ApiError extends Error {
    private statusCode: number;

    constructor(name: string, statusCode: number, message?: string) {
        super(message);
        this.name = name;
        this.statusCode = statusCode;
    }
}

export interface JobAlertsSubmissionValues {
    email: string;
    keyword: string;
    location: string;
    page_section?: string; // eslint-disable-line camelcase
    category?: string;
    level?: string;
    newsletter?: boolean;
    jobId?: number;
}

/**
 * job alerts optin from the public API.
 */
export const jobAlertsSubscribeRequest = async (values: JobAlertsSubmissionValues, axiosOptions?: AxiosRequestConfig) => {
    const { publicRuntimeConfig: { apiRequestTimeoutMs, apiBase } } = getConfig();

    const config: AxiosRequestConfig = {
        ...{
            timeout: apiRequestTimeoutMs,
            headers: {
                "Content-Type": "application/json",
            }
        },
        ...axiosOptions
    };
    const path = `${apiBase}/job_alerts/subscribe`;

    const enhancedValues = {
        page_section: "advice",
        ...values,
    };

    try {
        const response = await axios.post(path, enhancedValues, config);
        if (response.status === 200 || response.status === 201) {
            return true;
        }
        throw new Error(`Job Alerts subscription was unsuccessful. Status: ${response.status}`);
    } catch (error) {
        const msg = "Error: Job Alerts subscription was unsuccessful.";
        const err = JSON.stringify(error.response.data);
        console.error(msg, err);
        return false;
    }
};

/**
 * job alerts optin from the museweb service API.
 */
export const jobAlertsApiSubscribeRequest = async (values: JobAlertsSubmissionValues, axiosOptions?: AxiosRequestConfig) => {
    const {
        publicRuntimeConfig: {
            apiRequestTimeoutMs, museApiHost, museHost
        },
        serverRuntimeConfig: { emailAlertToken }
    } = getConfig();

    const config = {
        ...{
            timeout: apiRequestTimeoutMs,
            headers: {
                Authorization: `Shared-Token token=${emailAlertToken}`,
                Origin: museHost,
            }
        },
        ...axiosOptions
    };
    const path = `${museApiHost}/api/job_alerts/subscribe`;

    try {
        const response = await axios.post(path, values, config);
        return response.data;
    } catch (error) {
        const msg = `Error subscribing the user at ${path}, job ID: ${values.jobId}, status: ${error.response?.status}`;
        const err = JSON.stringify(error.response?.data?.error);
        console.error(msg, err);
        throw new ApiError("ApiError", error.response?.status, err);
    }
};

export const getFeaturedHomepageCompanies = async () => {
    const { serverRuntimeConfig: { articlesApiHost } } = getConfig();
    const { data } = await axios.get(`${articlesApiHost}/api/posts?uri=explore-companies`)
        .then((response) => response?.data ?? {})
        .catch((error: Error) => {
            const msg = "Article-renderer was unable to fetch the explore-companies section.";
            console.error(msg, JSON.stringify(formatLog({ error })));
            return { data: [] };
        });

    const {
        exploreCompaniesHeading = "",
        exploreCompaniesSubheading = "",
        featuredCompaniesHeading1 = "",
        featuredCompanies1 = [],
        featuredCompaniesHeading2 = "",
        featuredCompanies2 = [],
        featuredCompaniesHeading3 = "",
        featuredCompanies3 = []
    } = data?.pop?.() ?? {} as unknown as IFeaturedCompanies;

    return {
        exploreCompaniesHeading,
        exploreCompaniesSubheading,
        featuredCompaniesHeading1,
        featuredCompanies1,
        featuredCompaniesHeading2,
        featuredCompanies2,
        featuredCompaniesHeading3,
        featuredCompanies3
    };
};
